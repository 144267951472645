import { PlusOutlined } from '@ant-design/icons';
// import { RecurringBookingFilterFormValues } from '../recurring-bookings-filter-form';
import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { App, Grid } from 'antd';
import { handleError } from 'api';
import { Button, ContentFilters, ContentTopBar } from 'components';
import {
  MainFilters,
  MainFiltersParams,
} from 'components/main-filters/main-filters';
import dayjs from 'dayjs';
import { AllBookingStatsParams } from 'features/bookings/api/bookings-api.service';
import {
  BookingFilterFormValues,
  BookingsFilterForm,
} from 'features/bookings/components/bookings-filter-form';
import { ReportApiService, reportsQueryKeys } from 'features/reports';
import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ROUTES } from 'routes/constants';
import { AllBookingStatsResponse } from 'types/allbookingstats';
import { DayViewStatsResponse } from 'types/dayviewstats';
import { formatDate } from 'utils/date';

import AllBookingStatsComponent from './all-booking-stats-component';
import { AllBookingStatsFilterFormValues } from './types';
// import { RecurringBookingFilterFormValues } from '../recurring-bookings-filter-form';

const { useBreakpoint } = Grid;

type BookingTopBar = {
  setQueryParams: Dispatch<SetStateAction<MainFiltersParams | undefined>>;
  setQueryFilters: Dispatch<SetStateAction<BookingFilterFormValues>>;
  initialFilterValues: BookingFilterFormValues;
  resetTableCurrentPage: () => void;
};

export const BookingsTopBar = ({
  setQueryParams,
  setQueryFilters,
  initialFilterValues,
  resetTableCurrentPage,
}: BookingTopBar) => {
  const { t } = useTranslation();
  const screens = useBreakpoint();
  const { getAllBookingStats } = ReportApiService();
  const formId = 'booking-filters-form';
  const [company_id1, setCompanyId] = useState<any>('');
  const [facility_id1, setfacilityId] = useState<any>('');
  const [company_type1, setCompanyType] = useState<any>('');
  // const [setDate] = useState<any>('');
  const handleParams = ({
    company_type,
    company_id,
    facility_id,
  }: MainFiltersParams) => {
    resetTableCurrentPage();
    setQueryParams({
      company_type,
      company_id,
      facility_id,
    });
    setCompanyId(company_id);
    setfacilityId(facility_id);
    setCompanyType(company_type);
  };
  //console.log(initialFilterValues,"1 initialFilterValues");

  const handleFilter = (formData: BookingFilterFormValues) => {
    //console.log(formData,"formdata");

    resetTableCurrentPage();
    setQueryFilters(formData);
    // setDate(formData?.date);
    setQueryFilters1(formData as any);
  };

  const [queryFilters, setQueryFilters1] =
    useState<AllBookingStatsFilterFormValues>(initialFilterValues as any);
  const fromDate = queryFilters.date?.[0];
  const toDate = queryFilters.date?.[1];
  const useGetAllBookingStats = (
    params?: AllBookingStatsParams | undefined,
    options?: UseQueryOptions<DayViewStatsResponse, Error>,
  ) => {
    const { message } = App.useApp();

    return useQuery<AllBookingStatsResponse, Error>(
      reportsQueryKeys.list(params || {}),
      ({ signal }) => getAllBookingStats(params || {}, { config: { signal } }),
      {
        onError: (error: unknown) => {
          const uiMessage = handleError(error);
          message.error(uiMessage);
        },
        ...options,
      },
    );
  };
  const { data }: UseQueryResult<AllBookingStatsResponse, Error> =
    useGetAllBookingStats({
      // from_date:(date? date[0]?.toISOString() :null)  ,
      // to_date:date? date[1]?.toISOString() :null ,
      from_date: fromDate
        ? formatDate({
            date: dayjs(fromDate).startOf('day'),
            format: 'isoDate',
          })
        : undefined,
      to_date: toDate
        ? formatDate({
            date: dayjs(toDate).endOf('day'),
            format: 'isoDate',
          })
        : undefined,
      // from_date: initialFilterValues.date[0].toISOString(),
      // to_date:  initialFilterValues.date[1].toISOString(),
      company_type: company_type1 === undefined ? null : company_type1,
      company_id: company_id1 === undefined ? null : company_id1,
      facility_id: facility_id1 === undefined ? null : facility_id1,
    });
  // useEffect(() => {
  //   form.[BookingFilterFormField.Date] = ""
  // },[])

  return (
    <>
      <ContentTopBar
        title={t('bookings.heading.allBookings')}
        renderHeadingContent={<MainFilters onChange={handleParams} />}
        ctFilter={
          <ContentFilters formId={formId}>
            <BookingsFilterForm
              formId={formId}
              handleFilter={handleFilter}
              initialValues={initialFilterValues}
            />
          </ContentFilters>
        }
        renderButtons={
          <Button
            icon={!screens.xs ? <PlusOutlined /> : undefined}
            href={ROUTES.DAY_VIEW}
          >
            {t('bookings.buttons.addBooking')}
          </Button>
        }
      />
      {/* <p>Test</p> */}
      <AllBookingStatsComponent
        // date={date}
        // // initialFilterValues={initialFilterValues}
        // company_type= {company_type}
        // company_id={company_id}
        // facility_id={facility_id}
        data={data as any}
        // queryFilters={queryFilters}
      />

      {/* <ContentFilters formId={formId}>
        <BookingsFilterForm
          formId={formId}
          handleFilter={handleFilter}
          initialValues={initialFilterValues}
        />
      </ContentFilters> */}
    </>
  );
};
