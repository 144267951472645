export enum CreateFacilityField {
  Company = 'company',
  Area = 'area',
  FacilityName = 'facility_name',
  FacilityNameInArabic = 'facility_name_in_arabic',
  OpeningHours = 'opening_hours',
  Geolocation = 'geolocation',
  Image = 'image',
  Payment = 'payment',
}

export type CreateFacilityFormValues = {
  [CreateFacilityField.Company]: string;
  [CreateFacilityField.Area]: string;
  [CreateFacilityField.FacilityName]: string;
  [CreateFacilityField.FacilityNameInArabic]: string;
  [CreateFacilityField.OpeningHours]: [string, string];
  [CreateFacilityField.Geolocation]: string;
  [CreateFacilityField.Image]: unknown;
  [CreateFacilityField.Payment]: string[];
};
