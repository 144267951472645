import { Avatar, Card, Typography } from 'antd';
import styled from 'styled-components';

export const ContentHeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  padding: 32px 0 64px;
`;

export const CardWrapper = styled.div`
  display: flex;
  gap: 32px;
  justify-content: center;
  flex-wrap: wrap;
  padding-bottom: 32px;
`;

export const StyledCard = styled(Card)`
  background: ${({ theme }) => theme.antd.colorBorderSecondary};
  width: 214px;

  & .ant-card-body {
    padding: 24px 16px 16px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const StyledAvatar = styled(Avatar)`
  background: ${({ theme }) => theme.colors.pureWhite};
  width: 56px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
`;

export const StyledText = styled(Typography.Text)`
  display: inline-block;
  font-size: 1.6rem;
  font-weight: 500;
  margin-top: 24px;
`;

export const StyledButtonWrapper = styled.div`
  background: ${({ theme }) => theme.colors.pureWhite};
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  width: 100%;
  margin-top: 48px;
`;
