export enum UpdateFacilityField {
  Company = 'company',
  CompanyName = 'company_name',
  Area = 'area',
  FacilityName = 'facility_name',
  FacilityNameInArabic = 'facility_name_in_arabic',
  OpeningHours = 'opening_hours',
  Geolocation = 'geolocation',
  Image = 'image',
  Payment = 'payment',
  Rank = 'rank',
}

export type UpdateFacilityFormValues = {
  [UpdateFacilityField.Company]: string;
  [UpdateFacilityField.Area]: string;
  [UpdateFacilityField.FacilityName]: string;
  [UpdateFacilityField.FacilityNameInArabic]: string;
  [UpdateFacilityField.Rank]: number;
  [UpdateFacilityField.OpeningHours]: [string, string];
  [UpdateFacilityField.Geolocation]: string;
  [UpdateFacilityField.Image]: unknown;
  [UpdateFacilityField.Payment]: string[];
};
