import { Layout, Result404 } from 'components';
import { ResultError } from 'components/errors';
import { MainFiltersProvider } from 'components/main-filters/main-filters.context';
import { AccountSettingsView } from 'features/account-settings';
import { Amenities } from 'features/amenities/routes/amenities';
import { LoginView } from 'features/auth';
import {
  BookingDetailsView,
  DayView,
  RecurringBookingDetailsView,
  RecurringBookingsAddBookingView,
  RecurringBookingsView,
  StandardBookingsView,
} from 'features/bookings';
import { FixedBookingSingleBookingDetailsView } from 'features/bookings/routes/fixed-booking-single-booking-details';
import { RecurringBookingBookings } from 'features/bookings/routes/recurring-booking-bookings';
import { RecurringBookingInvoiceView } from 'features/bookings/routes/recurring-booking-invoice';
import { RecurringBookingsCommentsView } from 'features/bookings/routes/recurring-bookings-comments';
import { WeekView } from 'features/bookings/routes/week-view';
import {
  AddCompanyView,
  CompaniesView,
  CompanyDetailsView,
} from 'features/companies';
import { CompanyInfoView } from 'features/company-info/routes/company-info';
import { ContactUsView } from 'features/company-info/routes/contact-us';
import { PrivacyPolicyView } from 'features/company-info/routes/privacy-policy';
import { CreateFacilityView } from 'features/facilities/routes/create-facility';
import { FacilitiesView } from 'features/facilities/routes/facilities';
import { UpdateFacilityView } from 'features/facilities/routes/update-facility';
import { AddFieldView, FieldDetailsView, FieldsView } from 'features/fields';
import { ReportsView } from 'features/reports';
import { Sports } from 'features/sports/routes/sports';
import { AdminUsersView, AdminUserUpdateView } from 'features/users';
import { EndUserUpdateView } from 'features/users/routes/end-user-update';
import { EndUsersView } from 'features/users/routes/end-users';
import {
  UsersWithWalletView,
  WalletTransactionDetailsView,
  WalletTransactionHistoryView,
} from 'features/wallet';
import { createBrowserRouter, Navigate, RouteObject } from 'react-router-dom';

import { AuthenticatedRoute } from './authenticated-route';
import { ROUTES } from './constants';
import { RestrictedRoute } from './restricted-route';
import { UnauthenticatedRoute } from './unauthenticated-route';

export const routerConfig: RouteObject[] = [
  {
    errorElement: <ResultError />,
    children: [
      {
        path: ROUTES.LOGIN,
        element: (
          <UnauthenticatedRoute>
            <LoginView />
          </UnauthenticatedRoute>
        ),
      },
      {
        path: ROUTES.HOME,
        element: (
          <AuthenticatedRoute>
            <Layout />
          </AuthenticatedRoute>
        ),
        children: [
          {
            path: ROUTES.HOME,
            element: <Navigate to={ROUTES.BOOKINGS} replace />,
          },
          {
            path: ROUTES.ACCOUNT_SETTINGS,
            element: <AccountSettingsView />,
          },
          {
            path: ROUTES.BOOKINGS,
            element: (
              <MainFiltersProvider>
                <StandardBookingsView />
              </MainFiltersProvider>
            ),
            children: [
              {
                path: ROUTES.BOOKINGS_EDIT,
                element: <BookingDetailsView />,
              },
            ],
          },
          {
            path: ROUTES.FIXED_BOOKINGS,
            element: (
              <MainFiltersProvider>
                <RecurringBookingsView />
              </MainFiltersProvider>
            ),
          },
          {
            path: ROUTES.FIXED_BOOKINGS,
            element: <RecurringBookingDetailsView />,
            children: [
              {
                path: ROUTES.FIXED_BOOKING_DETAILS,
                element: <RecurringBookingBookings />,
                children: [
                  {
                    path: ROUTES.FIXED_BOOKINGS_EDIT,
                    element: <FixedBookingSingleBookingDetailsView />,
                  },
                ],
              },
              {
                path: ROUTES.FIXED_BOOKING_DETAILS_INVOICE,
                element: <RecurringBookingInvoiceView />,
              },
              {
                path: ROUTES.FIXED_BOOKING_DETAILS_COMMENTS,
                element: <RecurringBookingsCommentsView />,
              },
            ],
          },
          {
            path: ROUTES.FIXED_BOOKINGS_CREATE,
            element: <RecurringBookingsAddBookingView />,
          },
          {
            path: ROUTES.DAY_VIEW,
            element: (
              <MainFiltersProvider>
                <DayView />
              </MainFiltersProvider>
            ),
          },
          {
            path: ROUTES.WEEK_VIEW,
            element: (
              <MainFiltersProvider>
                <WeekView />
              </MainFiltersProvider>
            ),
          },
          {
            path: ROUTES.USERS,
            element: <Navigate to={ROUTES.USERS_APP} replace />,
          },
          {
            path: ROUTES.USERS_APP,
            element: (
              <RestrictedRoute
                redirectPath={ROUTES.HOME}
                requiredPermission='users.view'
              >
                <EndUsersView />
              </RestrictedRoute>
            ),
            children: [
              {
                path: ROUTES.USERS_APP_EDIT,
                element: (
                  <RestrictedRoute
                    redirectPath={ROUTES.HOME}
                    requiredPermission='users.edit'
                  >
                    <EndUserUpdateView />
                  </RestrictedRoute>
                ),
              },
            ],
          },
          {
            path: ROUTES.USERS_ADMIN,
            element: (
              <RestrictedRoute
                redirectPath={ROUTES.HOME}
                requiredPermission='adminUsers.view'
              >
                <AdminUsersView />
              </RestrictedRoute>
            ),
            children: [
              {
                path: ROUTES.USERS_ADMIN_EDIT,
                element: (
                  <RestrictedRoute
                    redirectPath={ROUTES.HOME}
                    requiredPermission='adminUsers.edit'
                  >
                    <AdminUserUpdateView />
                  </RestrictedRoute>
                ),
              },
            ],
          },
          //companies
          {
            path: ROUTES.COMPANIES,
            element: (
              <RestrictedRoute
                redirectPath={ROUTES.HOME}
                requiredPermission='company.view'
              >
                <MainFiltersProvider>
                  <CompaniesView />
                </MainFiltersProvider>
              </RestrictedRoute>
            ),
          },
          {
            path: ROUTES.COMPANIES_EDIT,
            element: (
              <RestrictedRoute
                redirectPath={ROUTES.HOME}
                requiredPermission='company.view'
              >
                <CompanyDetailsView />
              </RestrictedRoute>
            ),
          },
          {
            path: ROUTES.COMPANIES_CREATE,
            element: (
              <RestrictedRoute
                redirectPath={ROUTES.HOME}
                requiredPermission='company.add'
              >
                <AddCompanyView />
              </RestrictedRoute>
            ),
          },
          {
            path: ROUTES.FACILITIES,
            element: (
              <RestrictedRoute
                redirectPath={ROUTES.HOME}
                requiredPermission='facilities.view'
              >
                <MainFiltersProvider>
                  <FacilitiesView />
                </MainFiltersProvider>
              </RestrictedRoute>
            ),
          },
          {
            path: ROUTES.FACILITIES_CREATE,
            element: (
              <RestrictedRoute
                redirectPath={ROUTES.FACILITIES}
                requiredPermission='facilities.create'
              >
                <CreateFacilityView />
              </RestrictedRoute>
            ),
          },
          {
            path: ROUTES.FACILITIES_EDIT,
            element: (
              <RestrictedRoute
                redirectPath={ROUTES.FACILITIES}
                requiredPermission='facilities.edit'
              >
                <UpdateFacilityView />
              </RestrictedRoute>
            ),
          },
          {
            path: ROUTES.FIELDS,
            element: (
              <RestrictedRoute
                redirectPath={ROUTES.HOME}
                requiredPermission='fields.view'
              >
                <MainFiltersProvider>
                  <FieldsView />
                </MainFiltersProvider>
              </RestrictedRoute>
            ),
          },
          {
            path: ROUTES.FIELDS_CREATE,
            element: (
              <RestrictedRoute
                redirectPath={ROUTES.HOME}
                requiredPermission='fields.add'
              >
                <AddFieldView />
              </RestrictedRoute>
            ),
          },
          {
            path: ROUTES.FIELDS_EDIT,
            element: (
              <RestrictedRoute
                redirectPath={ROUTES.HOME}
                requiredPermission='fields.view'
              >
                <FieldDetailsView />
              </RestrictedRoute>
            ),
          },
          {
            path: ROUTES.SPORTS,
            element: (
              <RestrictedRoute
                redirectPath={ROUTES.HOME}
                requiredPermission='sports.view'
              >
                <Sports />
              </RestrictedRoute>
            ),
          },
          {
            path: ROUTES.AMENITIES,
            element: (
              <RestrictedRoute
                redirectPath={ROUTES.HOME}
                requiredPermission='amenities.view'
              >
                <Amenities />
              </RestrictedRoute>
            ),
          },
          {
            path: ROUTES.REPORTS,
            element: (
              <RestrictedRoute
                redirectPath={ROUTES.HOME}
                requiredPermission='reports.view'
              >
                <MainFiltersProvider>
                  <ReportsView />
                </MainFiltersProvider>
              </RestrictedRoute>
            ),
          },
          {
            path: ROUTES.WALLET,
            element: (
              <RestrictedRoute
                redirectPath={ROUTES.HOME}
                requiredPermission='wallet.view'
              >
                <UsersWithWalletView />
              </RestrictedRoute>
            ),
          },
          {
            path: ROUTES.WALLET_TRANSACTIONS,
            element: (
              <RestrictedRoute
                redirectPath={ROUTES.HOME}
                requiredPermission='wallet.view'
              >
                <WalletTransactionHistoryView />
              </RestrictedRoute>
            ),
            children: [
              {
                path: ROUTES.WALLET_TRANSACTION_DETAILS,
                element: <WalletTransactionDetailsView />,
              },
            ],
          },
          {
            path: ROUTES.COMPANY_INFO,
            element: <CompanyInfoView />,
            children: [
              {
                path: ROUTES.CONTACT_US,
                element: <ContactUsView />,
              },
              {
                path: ROUTES.PRIVACY_POLICY,
                element: <PrivacyPolicyView />,
              },
            ],
          },
        ],
      },
      {
        path: ROUTES.NOT_FOUND,
        element: <Result404 />,
      },
    ],
  },
];

export const router = createBrowserRouter(routerConfig);
