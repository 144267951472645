import { message } from 'antd';
import { ContentTopBar } from 'components';
import {
  MainFilters,
  MainFiltersParams,
} from 'components/main-filters/main-filters';
import dayjs, { Dayjs } from 'dayjs';
import {
  GetReportDTO,
  Reports,
  useGetAccountsReport,
  useGetConsolidatedReport,
  useGetSalesReport,
} from 'features/reports';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import { formatDate } from 'utils/date';
const getPayload = ({
  fromDate,
  toDate,
  queryParams,
}: {
  fromDate: Dayjs;
  toDate: Dayjs;
  queryParams?: MainFiltersParams;
}) => {
  const payload = {
    data: {
      from_date: formatDate({
        date: dayjs(fromDate).startOf('day'),
        format: 'dbDate',
      }),
      to_date: formatDate({
        date: dayjs(toDate).endOf('day'),
        format: 'dbDate',
      }),
    },
    params: queryParams,
  };

  return payload;
};

export const ReportsView = () => {
  const [queryParams, setQueryParams] = useState<MainFiltersParams>();
  const [queryFilters, setQueryFilters] = useState<
    [Dayjs | null, Dayjs | null] | null
  >();
  const { t } = useTranslation();
  const { downloadConsolidatedReport } = useGetConsolidatedReport();
  const { downloadAccountsReport } = useGetAccountsReport();
  const { downloadSalesReport } = useGetSalesReport();

  const handleParams = ({ company_type, company_id }: MainFiltersParams) => {
    setQueryParams({
      company_type,
      company_id,
    });
  };

  const handleDate = (date: [Dayjs | null, Dayjs | null] | null) =>
    setQueryFilters(date);

  const handleGetReport = (getReport: (payload: GetReportDTO) => void) => {
    if (queryFilters && !!queryFilters[0] && !!queryFilters[1]) {
      const fromDate = queryFilters[0];
      const toDate = queryFilters[1];

      const payload = getPayload({ queryParams, fromDate, toDate });

      return getReport(payload);
    } else {
      message.error('Please select date');
    }
  };

  const handleConsolidatedReport = () => {
    return handleGetReport(downloadConsolidatedReport);
  };

  const handleAccountsReport = () => {
    return handleGetReport(downloadAccountsReport);
  };
  const handleSalesReport = () => {
    return handleGetReport(downloadSalesReport);
  };

  return (
    <>
      <ContentTopBar
        title={t('reports.heading')}
        renderHeadingContent={
          <MainFilters
            onChange={handleParams}
            filterTypes={['companyType', 'company']}
          />
        }
      />
      <Reports
        handleDate={handleDate}
        handleConsolidatedReport={handleConsolidatedReport}
        handleAccountsReport={handleAccountsReport}
        handleSalesReport={handleSalesReport}
      />

      <Outlet />
    </>
  );
};
