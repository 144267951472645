import GraphikArabicMedium from 'assets/fonts/graphik-arabic-medium.woff2';
import GraphikArabicRegular from 'assets/fonts/graphik-arabic-regular.woff2';
import GraphikMedium from 'assets/fonts/graphik-medium.woff2';
import GraphikRegular from 'assets/fonts/graphik-regular.woff2';
import { dropdownMenu } from 'components/dropdown/dropdown.styles';
import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  /* http://meyerweb.com/eric/tools/css/reset/
    v2.0 | 20110126
    License: none (public domain)
  */

  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    font-family: 'Graphik', 'GraphikArabic', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    vertical-align: baseline;
  }

  /* setting font size to 10px for easier rem calculations */
  html { font-size: 62.5%; };

  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
    display: block;
  }
  body {
    line-height: 1;
  }
  ol, ul {
    list-style: none;
  }
  blockquote, q {
    quotes: none;
  }
  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  .ant-typography strong {
    font-weight: 500;
  }

  .prflbx{
        img{
          max-width: 125px;
        }
  }
  {}
  @font-face {
    font-family: 'Graphik';
    font-weight: 400;
    src: url(${GraphikRegular}) format('woff2');
  }

  @font-face {
    font-family: 'Graphik';
    font-weight: 500;
    src: url(${GraphikMedium}) format('woff2');
  }

  @font-face {
    font-family: 'GraphikArabic';
    font-weight: 400;
    src: url(${GraphikArabicRegular}) format('woff2');
  }


  @font-face {
    font-family: 'GraphikArabic';
    font-weight: 500;
    src: url(${GraphikArabicMedium}) format('woff2');
  }

  .ant-picker-dropdown {
    .ant-picker-today-btn {
      color: ${({ theme }) => theme.colors.greenLight};

      &:hover {
        color: ${({ theme }) => theme.colors.greenDark};
      }
    }
  }


  .day_v_head .sc-kDvujY.kdHSeD {
    gap: 10px !important;

    // flex-wrap:wrap;
}
  .filter_day_v {
    // background: #fff; padding: 4px 6px; border-radius: 6px; border: 1px solid #00473A;

    .ant-select-selector {
      padding: 0px !important;

      .ant-select-selection-placeholder {
        padding-inline-end: 30px;
      }
    }
}


 @media(max-width:900px){

  .global_slots{
    .slot_mbl{
      .halfHours{
        .sc-ckEbSK{
          display: flex; align-items: center;
          span.ant-typography{
            display:none;
          }
          .sc-GhhNo{
            width: 100%;
            span{
              display:inline-block;
            }
          }
        }
      }
    }
  }

  .day_v_head .sc-kDvujY{ display:block !important; }
  .filter_day_v{ min-width:100% !important; width:100%; margin-bottom: 6px !important; }
  .filter_day_v span.ant-select-clear {
    opacity: 1; width: 25px; height: 25px; background: #edeff6; display: flex; align-items: center; justify-content: center; 
    font-size: 30px; border-radius: 50%; top: 13px; right: 3px;
  }
  .stats_detls .row{ flex-wrap:wrap;  }
  .stats_detls .row .col:nth-child(2) { padding-right: 0;}
  .stats_detls .row .col { max-width: 50% !important ; width: 50% !important; padding-right: 15px; margin-bottom: 15px; }
  .stats_detls .row .col + .col{ padding-left:0px !important; }
  .stats_detls .row .col:last-child{ padding-right:0px; } 
  .ant-space-item .ant-modal-content .ant-modal-footer .sc-hLBbgP button.ant-btn { position: static; }
  .ant-space-item .sc-pyfCe .day_v_head a { right: 0 ;}
  .ant-row.fixed_bk_in{
    .ant-col{
      .ant-row.booking_dtls{
        .ant-col{
          .ant-row{
            .ant-col{max-width: 170px;width: auto;
            }
          }
        }
      }
    }
  }

  .ant-space-item .sc-pyfCe{
    &.cmpny_head{
      .day_v_head{
        .sc-csuSiG{
          .ant-typography{
            // overflow: hidden; text-overflow: ellipsis; display: inline-block; width: 61%;
          }
        }
      }
    }    
  }
  .ant-modal-wrap .ant-modal-content .ant-modal-body .ant-form .sc-gKPRtg .sc-iBYQkv .ant-form-item .ant-col.ant-form-item-control .ant-form-item-control-input .ant-picker span.ant-picker-clear {
    opacity: 1 !important; inset-inline-end: 11px !important;
  }

  .ant-picker-dropdown{
    left: 10px !important; margin: 0px; padding: 0px; right: 10px;
    .ant-picker-range-wrapper{
      padding: 15px; width: 100%;
      .ant-picker-panel-container{
        .ant-picker-panel-layout{
          display:block;
          .ant-picker-presets{
            width: 100%; min-width: 100%; max-width: 100%; display: block; border-bottom: 1px solid #ccc;
            ul{
              height: auto; display: flex; align-items: center; justify-content: space-between;
              li{
                font-size:14px;
                & + li{
                  margin:0px;
                }
              }
            }
          } 
  
          .ant-picker-panels{
            overflow: auto; width: 100%;
            // display:block;
            .ant-picker-panel{
              width:100%;
              .ant-picker-date-panel{
                width:100%;
                .ant-picker-body{
                  .ant-picker-content{
                    width:100%;
                  }
                }
              }
            }
          }
  
        }
      }
    }
  }

}
 


  // Remove the flex gap from component

  .no-gap {
     gap: 0;
  }

  .ant-space-item{
    .sc-idXgbr.cdZltE{
      margin-top:15px;
    }
  }

  .stats_detls {
    margin-bottom: 15px;
    .row {
      display: flex; align-items: center;
      .col {
        max-width: 33.3%; width: 33.3%;
        &:nth-child(1) .stats_bx{
          background:#4E7E4C; 
          // span{color:#df9c5a;}
          // h4{ color:#975e26;}
        }
        &:nth-child(2) .stats_bx{
          background:#009EDB; 
          // span{color:#6b9d9d;}
          // h4{ color:#2e7070;}
        }
        &:nth-child(3) .stats_bx{
          background:#015B97; 
          // span{color:#8666b5;}
          // h4{ color:#5e359b;}
        }
        &:nth-child(4) .stats_bx{
          background:#CD7640; 
          // span{color:#5fa978;}
          // h4{ color:#31874e;}
        }
        
        .stats_bx {
          background: #fff; padding: 10px; border-radius: 5px; border: 1px solid #dbdbdb;height: 70px;position:relative;
          span {
            font-size: 14px; color: #4f5051; font-weight: 500; color:#fff;
          }
          h4 {
            font-size: 20px; color: #00473a; font-weight: 500; text-align:right; color:#fff;
          }
          &:hover{
            box-shadow:0px 1px 6px #00473a40; 
          }         
        }
        & + .col{
          padding-left:15px;
        }

      }
    }
  }
 
  
   


  .loader {
    border: 3px solid #f3f3f3;
    border-top: 3px solid #00473a;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
    position: absolute;
    right: 13px;
    bottom: 11px;
}
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }








  /* 24-02-2024 All Booking Page / Vikas / */


 

.ant-space-item{

  .sc-pyfCe{
    position: relative;    
    .sc-jrcTuL{    
      width: 100%; display: block; grid-template-columns: auto auto auto; gap: 0px 10px; align-items: start;           
      .sc-csuSiG{
        width: 100%;  grid-column: 1 / span 3; margin-bottom: 10px;
        h3{
          margin:0px;
        }
      }
      
      .sc-kDvujY:nth-child(2){
        display: inline-block; vertical-align: top;
        .sc-kgTSHT {
          min-width: 110px; margin: 0px; padding: 0px;
          .ant-select-selector{
            .ant-select-selection-item{
              padding-inline-end: 25px; 
            }
            .ant-select-selection-placeholder{
              padding-inline-end: 25px; font-size:14px;
            }
          }
        }
      }
      
      .sc-kDvujY:nth-child(3){     
        display: inline-block;
        
        .sc-eDvSVe{
          padding:0px; background: transparent;
          .ant-form{
            align-items: center; gap:10px;
            .sc-gKPRtg{
              flex-direction: row;
              .sc-iBYQkv{
                gap:10px;
                .ant-form-item {
                  margin:0px;                  
                  
                  &:nth-child(3){ width: 135px; }
                  &:nth-child(2){ width: 115px; }

                  &:nth-child(1){
                    .ant-row{
                      flex-direction: unset;
                      .ant-col{
                        .ant-form-item-control-input{
                          .ant-form-item-control-input-content{
                            .ant-picker{
                              max-width: 195px; padding: 4px 7px;
                              .ant-picker-input{}
                              .ant-picker-range-separator{
                                display:none;
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                  &:nth-child(2){}
                  &:nth-child(3){}

                  .ant-row{
                    flex-direction: unset;
                    .ant-col{
                      label{
                        display:none;
                      }
                    }
                  }
                }
              }
              .sc-ftTHYK{
                display: flex; height: auto; padding-left:10px;
                .ant-form-item{
                  margin:0px;
                  & + .ant-form-item{
                    margin-left:10px;
                  }
                  .ant-row{
                    flex-direction: unset;
                    .ant-col{
                      label{
                        display:none
                      }
                    }
                  }
                }
              }
            }
            .sc-hLBbgP{
              width: auto; margin: 0px;
              .ant-btn{
                position: relative;
                

                &:nth-child(2){}
                &:nth-child(3){
                  display:none;
                }
              }
            }
          }
        }
      }
    }
  }
}








  @media (min-width: 1024px) and (max-width: 1450px) {

    .ant-space-item{
      .sc-dkKxlM{
        margin:0px 0px 10px 0px;
      }

    .sc-pyfCe{
      // display: block; 
      position: relative; margin-bottom: 10px;      
        .ant-btn.ant-btn-default {
          padding: 5px 10px; font-size: 12px; height: auto;border-color: #d9d9d9; color: rgba(0, 0, 0, 0.88) ;
        }  
      .sc-jrcTuL{    
        width: 100%; display: block; grid-template-columns: auto auto auto; gap: 0px 10px; align-items: start;           
          .sc-csuSiG{
            width: 100%;  grid-column: 1 / span 3; margin-bottom: 10px;
            h3{
              margin:0px;
            }
          }
          .sc-kDvujY:nth-child(2){
            display: inline-block; vertical-align: top;
            // width: 29%;
              .sc-kgTSHT {
                min-width: auto; 
                .ant-select-selector{
                  .ant-select-selection-item{
                    padding-inline-end: 25px; font-size:12px;
                  }
                  .ant-select-selection-placeholder{
                    padding-inline-end: 25px; font-size:12px;
                  }
                }
              }
          }
        .sc-kDvujY:nth-child(3){     
          display: inline-block; 
          // width: 71%;
          
          .sc-eDvSVe{
            padding:0px; background: transparent;
            .ant-form{
              align-items: center; gap:10px;
              .sc-gKPRtg{
                flex-direction: row;
                .sc-iBYQkv{
                  gap:5px;
                  .ant-form-item {
                    margin:0px;     
                    
                    .ant-row{
                      .ant-col{
                        .ant-form-item-control-input{
                          .ant-form-item-control-input-content{
                            .ant-input{
                              font-size:12px; padding-left: 7px; padding-right: 7px;
                            }
                          }
                        }
                      }
                    }
                    
                    &:nth-child(3){ width: 115px; }
                    &:nth-child(2){ width: 115px; }
  
                    &:nth-child(1){
                      .ant-row{
                        flex-direction: unset;
                        .ant-col{
                          .ant-form-item-control-input{
                            .ant-form-item-control-input-content{
                              .ant-picker{
                                max-width: 170px; padding: 4px 5px;                             
                                .ant-picker-input{
                                  input{
                                    font-size:12px;
                                  }
                                }
                                .ant-picker-range-separator{
                                  display:none;
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  
                   
  
                    .ant-row{
                      flex-direction: unset;
                      .ant-col{
                        label{
                          display:none;
                        }
                      }
                    }
                  }
                }
                .sc-ftTHYK{
                  display: flex; height: auto; padding-left:10px;
                  .ant-form-item{
                    margin:0px;
                    & + .ant-form-item{
                      margin-left:10px;
                    }
                    .ant-row{
                      flex-direction: unset;
                      .ant-col{
                        label{
                          display:none
                        }
                        .ant-form-item-control-input{
                          .ant-form-item-control-input-content{
                            .ant-select{
                              .ant-select-selector{
                                padding: 0 7px; height:28px;
                                .ant-select-selection-search{
                                  .ant-select-selection-search-input{
                                    font-size:12px; height:28px;
                                  }
                                }
                                .ant-select-selection-item{
                                  display: block; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;
                                  font-size: 12px; width: 95px;
                                }
                                .ant-select-selection-placeholder{
                                  font-size:12px;
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              .sc-hLBbgP{
                width: auto; margin: 0px;
                .ant-btn{
                  font-size: 12px; height:28px; padding-top: 4px;
                  
                  &:nth-child(3){
                    display:none;
                  }

                }
              }
            }
          }
        }
      }      
    }        
  }  
}



  .sc-idXgbr{
    margin-top: 20px;
  }
  .ant-space-item .sc-khsqcC {
    margin-top: 16px !important;
}


#booking-filters-form .ant-form-item .ant-col.ant-form-item-control .ant-picker span.ant-picker-clear {
  inset-inline-end: 5px;
}
 

.ant-space-item .sc-pyfCe .sc-bRlCZA .ant-btn.ant-btn-default.ant-btn-dangerous { 
 color: #ff4d4f; border-color: #ff4d4f;
  span{
    color: #ff4d4f;
  }
}



span.ant-typography.ant-typography-secondary.css-inhf9z { font-size: 13px;}


.day_view_clg{
  .sc-jfTVlA{
    height: calc(100vh - calc(267px + 32px)); overflow: auto; overflow: auto;
    .sc-hiDMwi{ overflow:visible;}
  }
  .sc-lbVpMG{ position: sticky; top: 0px; z-index: 3; background: #fff; left: 0px;}
  .sc-iOeugr{ position: sticky; top: 0px; z-index: 2; overflow: visible; padding-right:0px;}
  .sc-eJDSGI{ position: sticky; left:0px; overflow: visible; background: #fff; }
}


.bknghead{
  .day_v_head{}
  .mltyplbtn{
    position: absolute; right: 0; top: 0;
    .ant-btn.ant-btn-default{}
  }
}

.cstmhead{
  display:flex;
  .day_v_head{}
  .mltyplbtn{
    .ant-space-item{
      & + .ant-space-item{
        .ant-btn.ant-btn-default{}
      }
    }
    .ant-btn.ant-btn-default{}
  }
}



@media screen and (max-width: 980px) {  
  
  .anticon.anticon-menu.app-header-hamburger-mobile{ display:inline-flex; }

  .ant-layout-sider-below.side-bar-sider{    
    &:after{
      content: ''; background: #00473a14; left: 0px; right: 0px; top: 0px; bottom: 0px; position: fixed; z-index: -1;
    }
    &.ant-layout-sider-collapsed.ant-layout-sider-zero-width, &.ant-layout-sider-collapsed.ant-layout-sider-has-trigger {
      &:after{
        display: none;
      }  
    }
  }

  .cmpny_head{
    .day_v_head{
      .mltyplbtn{
        position: unset; right: 0; top: 0;        
      }
    }
  }
 

  .cstmhead{    
    .day_v_head{
      .sc-csuSiG{
        gap: 5px;
        .ant-typography{
          white-space:normal; font-size: 20px;
        }
      }
      .mltyplbtn{
        position: unset; right: 0; top: 0;
      }
    }     
  }



  .day_view_clg{
    .sc-jfTVlA{
      grid-template-rows: 50px auto;
      .sc-iOeugr{
        .sc-jfvxQR{
          .field_mbl{
            .ant-typography.ant-typography-ellipsis{
              font-size:16px;
            }
            .sc-cOxWqc{display:none;}
            .sc-dPWrhe{display:none;}
            .sc-jOiSOi{display:none;}
          }
        }
      }
    }
    .slot_mbl{
      .halfHours{
        .sc-ckEbSK{
          display: flex; align-items: unset; line-height: 42px;
          span.ant-typography{
            display:none;
          }
          .sc-GhhNo{
            width: 100%;
            span{
              display:inline-block; line-height: 42px;
            }
          }
        }
      }
    }
  }  
}
    
  
  
}









  ${dropdownMenu};

  .input-prefix-icon { color: ${({ theme }) =>
    theme.antd.colorIcon}; margin-right: 8px; };

`;
