import {
  ApartmentOutlined,
  BarChartOutlined,
  OrderedListOutlined,
  ProjectOutlined,
  RetweetOutlined,
  TeamOutlined,
  WalletOutlined,
} from '@ant-design/icons';
import { Permissions } from 'features/users';
import i18n from 'locales/i18n';
import { NavLink } from 'react-router-dom';
import { ROUTES } from 'routes/constants';

import { ItemWithEnforcedKey } from './menu-items.types';

// Important! This has to be a function, otherwise labels are not rendered in tests
export const getMenuItems = (
  permissions: Permissions,
): ItemWithEnforcedKey[] => [
  {
    key: ROUTES.BOOKINGS,
    label: (
      <NavLink to={ROUTES.BOOKINGS}>
        {i18n.t('sideBar.menu.bookings.all')}
      </NavLink>
    ),
    icon: <OrderedListOutlined />,
  },
  {
    key: ROUTES.FIXED_BOOKINGS,
    label: (
      <NavLink to={ROUTES.FIXED_BOOKINGS}>
        {i18n.t('sideBar.menu.bookings.fixed')}
      </NavLink>
    ),
    icon: <RetweetOutlined />,
  },
  {
    key: ROUTES.DAY_VIEW,
    label: (
      <NavLink to={ROUTES.DAY_VIEW}>{i18n.t('sideBar.menu.dayView')}</NavLink>
    ),
    icon: <ProjectOutlined />,
  },
  {
    key: ROUTES.WEEK_VIEW,
    label: (
      <NavLink to={ROUTES.WEEK_VIEW}>{i18n.t('sideBar.menu.weekView')}</NavLink>
    ),
    icon: <ProjectOutlined />,
  },
  permissions.users.view
    ? {
        key: ROUTES.USERS,
        label: i18n.t('sideBar.menu.users.all'),
        icon: <TeamOutlined />,
        children: [
          {
            key: ROUTES.USERS_APP,
            label: (
              <NavLink to={ROUTES.USERS_APP}>
                {i18n.t('sideBar.menu.users.app')}
              </NavLink>
            ),
          },
          {
            key: ROUTES.USERS_ADMIN,
            label: (
              <NavLink to={ROUTES.USERS_ADMIN}>
                {i18n.t('sideBar.menu.users.admin')}
              </NavLink>
            ),
          },
        ],
      }
    : null,
  permissions.company.view
    ? {
        key: ROUTES.COMPANIES_ALL,
        label: i18n.t('sideBar.menu.companies.all'),
        icon: <ApartmentOutlined />,
        children: [
          {
            key: ROUTES.COMPANIES,
            label: (
              <NavLink to={ROUTES.COMPANIES}>
                {i18n.t('sideBar.menu.companies.companies')}
              </NavLink>
            ),
          },
          {
            key: ROUTES.FACILITIES,
            label: (
              <NavLink to={ROUTES.FACILITIES}>
                {i18n.t('sideBar.menu.companies.facilities')}
              </NavLink>
            ),
          },
          {
            key: ROUTES.FIELDS,
            label: (
              <NavLink to={ROUTES.FIELDS}>
                {i18n.t('sideBar.menu.companies.fields')}
              </NavLink>
            ),
          },
          {
            key: ROUTES.SPORTS,
            label: (
              <NavLink to={ROUTES.SPORTS}>
                {i18n.t('sideBar.menu.companies.sports')}
              </NavLink>
            ),
          },
          {
            key: ROUTES.AMENITIES,
            label: (
              <NavLink to={ROUTES.AMENITIES}>
                {i18n.t('sideBar.menu.companies.amenities')}
              </NavLink>
            ),
          },
        ],
      }
    : null,
  permissions.reports.view
    ? {
        key: ROUTES.REPORTS,
        label: (
          <NavLink to={ROUTES.REPORTS}>
            {i18n.t('sideBar.menu.reports')}
          </NavLink>
        ),
        icon: <BarChartOutlined />,
      }
    : null,
  permissions.wallet.view
    ? {
        key: ROUTES.WALLET,
        label: (
          <NavLink to={ROUTES.WALLET}>{i18n.t('sideBar.menu.wallet')}</NavLink>
        ),
        icon: <WalletOutlined />,
      }
    : null,
  {
    key: ROUTES.CONTACT,
    label: i18n.t('sideBar.menu.companyInfo.all'),
    icon: <TeamOutlined />,
    children: [
      {
        key: ROUTES.CONTACT_US,
        label: (
          <NavLink to={ROUTES.CONTACT_US}>
            {i18n.t('sideBar.menu.companyInfo.contactUs')}
          </NavLink>
        ),
      },
      {
        key: ROUTES.PRIVACY_POLICY,
        label: (
          <NavLink to={ROUTES.PRIVACY_POLICY}>
            {i18n.t('sideBar.menu.companyInfo.privacyPolicy')}
          </NavLink>
        ),
      },
    ],
  },
];
