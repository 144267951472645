import { Checkbox, Col, Form, Grid, Input, Row, Upload } from 'antd';
import { RcFile } from 'antd/lib/upload';
import ImgCrop from 'antd-img-crop';
import { Button } from 'components';
import { CompanySelect } from 'components/main-filters/company-select';
import { RouteLeavingGuard } from 'components/route-leaving-guard/route-leaving-guard';
import { AreaSelect } from 'features/areas/components/area-select/area-select';
import { useUploadProps } from 'hooks/use-upload-props';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PaymentMethod } from 'types';
import { paymentMethodTranslationsMap } from 'utils/transaction';
import { geolocationPattern } from 'utils/validations';

import { CreateFacilityField, CreateFacilityFormValues } from './types';

const { useBreakpoint } = Grid;

type CreateFacilityFormProps = {
  handleUpdate: (object: CreateFacilityFormValues, FileList: RcFile[]) => void;
  disabled: boolean;
};

export const CreateFacilityForm = ({
  handleUpdate,
  disabled,
}: CreateFacilityFormProps) => {
  const formName = 'add-facility-form';
  const screens = useBreakpoint();
  const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
  const [form] = Form.useForm<CreateFacilityFormValues>();
  const { t } = useTranslation();

  const { fileList, uploadProps } = useUploadProps();

  const [isFormDirty, setIsFormDirty] = useState(false);

  useEffect(() => {
    if (fileList.length) setIsFormDirty(true);
  }, [fileList]);

  const formItemLayout = screens.lg
    ? { labelCol: { span: 7 }, wrapperCol: { span: 10 } }
    : null;

  const getInitialValues = {
    [CreateFacilityField.Company]: undefined,
    [CreateFacilityField.Area]: undefined,
    [CreateFacilityField.FacilityName]: undefined,
    [CreateFacilityField.FacilityNameInArabic]: undefined,
    [CreateFacilityField.Geolocation]: undefined,
    [CreateFacilityField.Image]: undefined,
    [CreateFacilityField.Payment]: undefined,
  };

  const paymentOptions = [
    {
      value: PaymentMethod.Cash,
      label: paymentMethodTranslationsMap[PaymentMethod.Cash],
    },
    {
      value: PaymentMethod.Knet,
      label: paymentMethodTranslationsMap[PaymentMethod.Knet],
    },
    {
      value: PaymentMethod.Wallet,
      label: paymentMethodTranslationsMap[PaymentMethod.Wallet],
    },
    {
      value: PaymentMethod.Credit,
      label: paymentMethodTranslationsMap[PaymentMethod.Credit],
    },
    {
      value: PaymentMethod.Apple,
      label: paymentMethodTranslationsMap[PaymentMethod.Apple],
    },
  ];

  const onFinish = (formValues: CreateFacilityFormValues) => {
    setIsFormDirty(false);
    handleUpdate(formValues, fileList as RcFile[]);
  };

  return (
    <>
      <Form<CreateFacilityFormValues>
        {...formItemLayout}
        id={formName}
        name={formName}
        form={form}
        layout={screens.lg ? 'horizontal' : 'vertical'}
        initialValues={getInitialValues}
        onFinish={onFinish}
        requiredMark={'optional'}
        onFieldsChange={() => setIsFormDirty(true)}
        onFinishFailed={() => {
          setValidateTrigger(['onChange']);
        }}
        validateTrigger={validateTrigger}
        disabled={disabled}
      >
        <Form.Item<CreateFacilityField>
          label={t('facilities.formFields.company.label')}
          name={CreateFacilityField.Company}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <CompanySelect
            theme={'standard'}
            companyType={undefined}
            bordered={true}
            style={{ maxWidth: '100%' }}
            placeholder={t('facilities.formFields.company.placeholder')}
          />
        </Form.Item>
        <Form.Item<CreateFacilityField>
          label={t('facilities.formFields.area.label')}
          name={CreateFacilityField.Area}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <AreaSelect
            placeholder={t('facilities.formFields.area.placeholder')}
          />
        </Form.Item>
        <Form.Item<CreateFacilityField>
          label={t('facilities.formFields.facilityName.label')}
          name={CreateFacilityField.FacilityName}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input
            placeholder={t('facilities.formFields.facilityName.placeholder')}
          />
        </Form.Item>
        <Form.Item<CreateFacilityField>
          label={t('facilities.formFields.facilityArabic.label')}
          name={CreateFacilityField.FacilityNameInArabic}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input
            placeholder={t('facilities.formFields.facilityArabic.placeholder')}
            dir='rtl'
          />
        </Form.Item>
        <Form.Item<CreateFacilityField>
          label={t('facilities.formFields.geolocation.label')}
          name={CreateFacilityField.Geolocation}
          rules={[
            {
              required: true,
            },
            {
              pattern: geolocationPattern,
              message: t('validateMessages.custom.geolocation'),
            },
          ]}
        >
          <Input
            placeholder={t('facilities.formFields.geolocation.placeholder')}
          />
        </Form.Item>
        <Form.Item
          label={t('facilities.formFields.image.label')}
          name={CreateFacilityField.Image}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <ImgCrop rotationSlider aspect={2}>
            <Upload
              {...uploadProps}
              name={CreateFacilityField.Image}
              accept='.png, .jpg, .jpeg'
              maxCount={1}
              listType='picture-card'
              onChange={(value) => {
                form.setFieldValue(CreateFacilityField.Image, value.file);
                form.validateFields([CreateFacilityField.Image]);
              }}
            >
              {fileList.length < 1 && '+ Upload'}
            </Upload>
          </ImgCrop>
        </Form.Item>
        <Form.Item<CreateFacilityField>
          label={t('facilities.formFields.payment.label')}
          name={CreateFacilityField.Payment}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Checkbox.Group options={paymentOptions} />
        </Form.Item>

        <Col span={screens.lg ? 10 : 'auto'} offset={screens.lg ? 7 : 0}>
          <Row justify='end'>
            <Button disabled={disabled} type='primary' htmlType='submit'>
              {t('facilities.buttons.create')}
            </Button>
          </Row>
        </Col>
      </Form>

      <RouteLeavingGuard blocked={isFormDirty} />
    </>
  );
};
