import { Grid, TablePaginationConfig } from 'antd';
import { PaginatedRequestParams } from 'api';
import { Column, RecordCountBadge, Table } from 'components';
import {
  StyledTableHeader,
  StyledTableWrapper,
} from 'components/table/table.styled';
import { getUserFullName } from 'features/users';
import { WalletTransactionsHistoryResponse } from 'features/wallet/api';
import {
  formatWalletTransactionAmount,
  mapWalletTransactionComment,
} from 'features/wallet/utils';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { RoutePaths, ROUTES } from 'routes/constants';
import { getRoute } from 'routes/utils';
import { WalletTransactionsHistoryItem } from 'types';
import { formatPrice } from 'utils/currency';
import { formatDate } from 'utils/date';

import { StyledTransactionDateTime } from './wallet-transaction-history-table.styles';

const { useBreakpoint } = Grid;

type WalletTransactionHistoryTableProps = {
  isLoading: boolean;
  data?: WalletTransactionsHistoryResponse;
  tableParams: PaginatedRequestParams;
  updateParams: Dispatch<SetStateAction<PaginatedRequestParams>>;
  walletTransactionId: string;
};

export const WalletTransactionHistoryTable = ({
  data,
  isLoading,
  tableParams,
  updateParams,
  walletTransactionId,
}: WalletTransactionHistoryTableProps) => {
  const { t } = useTranslation();
  const screens = useBreakpoint();
  const navigate = useNavigate();

  const handleTableChange = (pagination: TablePaginationConfig) => {
    updateParams({
      ...tableParams,
      page: pagination.current,
      size: pagination.pageSize,
    });
  };

  const getDetailsRoute = (
    route: RoutePaths,
    id: string | number,
    transactionId: string | number,
  ) => {
    return getRoute(route, id).replace(
      `:transaction_id`,
      transactionId.toString(),
    );
  };

  return (
    <StyledTableWrapper>
      <StyledTableHeader>
        <RecordCountBadge
          text={t('wallet.foundUsers')}
          count={data?.total || 0}
        />
      </StyledTableHeader>
      <Table<WalletTransactionsHistoryItem>
        loading={isLoading}
        dataSource={data?.items}
        rowKey='id'
        onChange={handleTableChange}
        pagination={{
          current: tableParams.page,
          defaultPageSize: tableParams.size,
          total: data?.total,
        }}
        scroll={{ x: 1440 }}
        onRow={(record) => {
          return {
            onClick: () => {
              const selection = window?.getSelection()?.toString();

              if (!selection?.length) {
                navigate(
                  getDetailsRoute(
                    ROUTES.WALLET_TRANSACTION_DETAILS,
                    walletTransactionId,
                    record.id,
                  ),
                );
              }
            },
          };
        }}
      >
        <Column<WalletTransactionsHistoryItem>
          title={t('wallet.transactionsHistory.table.amount')}
          dataIndex={'amount'}
          width={screens.xxl ? 'auto' : 130}
          render={(_, record) =>
            formatWalletTransactionAmount({
              value: record.amount,
              currency: record.transaction[0].currency,
              transactionType: record.type,
              addColor: true,
            })
          }
          ellipsis
        />
        <Column<WalletTransactionsHistoryItem>
          title={t('wallet.transactionsHistory.table.balance')}
          dataIndex={'balance'}
          width={screens.xxl ? 'auto' : 150}
          render={(_, record) =>
            formatPrice({
              value: record.balance_after,
              currency: 'KWD',
            })
          }
          ellipsis
        />
        <Column<WalletTransactionsHistoryItem>
          title={t('wallet.transactionsHistory.table.comment')}
          dataIndex={'comment'}
          render={(_, record) =>
            mapWalletTransactionComment({
              source: record.source,
              transactionType: record.type,
            })
          }
          ellipsis
        />
        <Column<WalletTransactionsHistoryItem>
          title={t('wallet.transactionsHistory.table.transactionDate')}
          dataIndex={'created_at'}
          width={screens.xxl ? 'auto' : 150}
          render={(_, record) => (
            <>
              {formatDate({
                date: record.created_at,
                format: 'date',
              })}
              <StyledTransactionDateTime>
                {formatDate({
                  date: record.created_at,
                  format: 'time',
                })}
              </StyledTransactionDateTime>
            </>
          )}
          ellipsis
        />
        <Column<WalletTransactionsHistoryItem>
          title={t('wallet.transactionsHistory.table.executedBy')}
          dataIndex={'executed_by'}
          render={(_, record) => getUserFullName(record.created_by)}
          ellipsis
        />
        <Column<WalletTransactionsHistoryItem>
          title={t('wallet.transactionsHistory.table.fieldName')}
          dataIndex={['transaction', '0', 'booking', 'field', 'name']}
          ellipsis
        />
        <Column<WalletTransactionsHistoryItem>
          title={t('wallet.transactionsHistory.table.scheduleTime')}
          dataIndex={'scheduleTime'}
          width={screens.xxl ? 'auto' : 150}
          render={(_, record) =>
            record?.transaction[0]?.booking ? (
              <>
                {formatDate({
                  date: record?.transaction[0]?.booking?.start_date,
                  format: 'date',
                })}
                <StyledTransactionDateTime>
                  {formatDate({
                    date: record?.transaction[0]?.booking?.start_date,
                    format: 'time',
                  })}
                </StyledTransactionDateTime>
              </>
            ) : null
          }
          ellipsis
        />
        <Column<WalletTransactionsHistoryItem>
          title={t('wallet.transactionsHistory.table.price')}
          dataIndex={'price'}
          width={screens.xxl ? 'auto' : 130}
          render={(_, record) =>
            record?.transaction[0]?.booking
              ? formatPrice({
                  value: record?.amount,
                  currency: record?.transaction[0]?.currency,
                })
              : null
          }
          ellipsis
        />
      </Table>
    </StyledTableWrapper>
  );
};
