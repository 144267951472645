import { Typography } from 'antd';
import { eachDayOfInterval, endOfWeek, startOfWeek } from 'date-fns';
import { FieldBookingsResponse } from 'features/fields';
import { useUserContext } from 'features/users';
import React, { useLayoutEffect, useRef } from 'react';
import { theme } from 'styles';
import { getLocalizedFullHours } from 'utils/date';

import { BookingTimetableContent } from './booking-timetable-content';
import {
  AxisColumn,
  EmptyCell,
  HeaderRow,
  ScaleHourBlock,
  StyledHeadersWrapper,
  StyledTimetableContent,
} from './fields-timetable.styles';
import { getTimeRange } from './utils';
import { WeekHeader } from './week-header';

type WeekTimetableProps = {
  data: FieldBookingsResponse;
  date: string;
  filteredSportId?: string;
};

const HOUR_BLOCK_HEIGHT_IN_PIXELS =
  theme.constants.fieldsTimetable.oneHourSlotHeight;

export const WeekTimetable = ({
  data,
  date,
  filteredSportId,
}: WeekTimetableProps) => {
  let preventEvent = false;
  let contentLastScrollTop = 0;
  let contentLastScrollLeft = 0;

  const headerRef = useRef<HTMLDivElement>(null);
  const axisRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const { user } = useUserContext();

  const currentHour = new Date().getHours();

  function removeOneHourIf30Minutes(timeStr: string) {
    const timeParts = timeStr.split(':');

    if (timeParts.length === 3) {
      const hours = parseInt(timeParts[0]);
      const minutes = parseInt(timeParts[1]);

      if (minutes === 30) {
        timeParts[0] = hours.toString().padStart(2, '0');
        timeParts[1] = '00';
      }

      return timeParts.join(':');
    } else {
      return timeStr;
    }
  }

  const timelineHours = {
    startTime: removeOneHourIf30Minutes(data.open_from),
    endTime: data.open_to || data.open_to,
  };

  // const timelineHours = {
  ///   startTime: removeOneHourIf30Minutes(data.time_ranges[0].start_time),
  //   endTime: data.time_ranges[2].end_time,
  // };

  const timelineRange = getTimeRange(timelineHours);
  const timelineRangeLabels = getLocalizedFullHours(timelineRange);

  useLayoutEffect(() => {
    const currentHourIndex = timelineRange.indexOf(currentHour);
    const lastOpenHourIndex = timelineRange.length - 1;
    const scrollContentTo = (hourIndex: number) => {
      if (contentRef.current) {
        contentRef.current.scrollTop = hourIndex * HOUR_BLOCK_HEIGHT_IN_PIXELS;
      }
    };

    if (currentHourIndex > 0) {
      scrollContentTo(currentHourIndex);
    } else if (currentHour > timelineRange[lastOpenHourIndex]) {
      scrollContentTo(lastOpenHourIndex);
    }
  }, [currentHour, timelineRange]);

  if (!user) return null;

  // TODO: improve performance of all scrolling functions with requestAnimationFrame
  const onHeaderScroll = (event: React.UIEvent<HTMLElement>) => {
    if (preventEvent) {
      preventEvent = false;

      return;
    }

    preventEvent = true;

    if (contentRef.current) {
      contentRef.current.scrollLeft = event.currentTarget.scrollLeft;
    }
  };

  const onAxisScroll = (event: React.UIEvent<HTMLElement>) => {
    if (preventEvent) {
      preventEvent = false;

      return;
    }

    preventEvent = true;

    if (contentRef.current) {
      contentRef.current.scrollTop = event.currentTarget.scrollTop;
    }
  };

  const onContentScroll = (event: React.UIEvent<HTMLElement>) => {
    if (preventEvent) {
      preventEvent = false;

      return;
    }

    if (event.currentTarget.scrollTop !== contentLastScrollTop) {
      preventEvent = true;

      if (axisRef.current) {
        axisRef.current.scrollTop = event.currentTarget.scrollTop;
        contentLastScrollTop = event.currentTarget.scrollTop;
      }
    }

    if (event.currentTarget.scrollLeft !== contentLastScrollLeft) {
      preventEvent = true;

      if (headerRef.current) {
        headerRef.current.scrollLeft = event.currentTarget.scrollLeft;
        contentLastScrollLeft = event.currentTarget.scrollLeft;
      }
    }
  };

  const dateRange = eachDayOfInterval({
    start: startOfWeek(new Date(date), { weekStartsOn: 0 }),
    end: endOfWeek(new Date(date), { weekStartsOn: 0 }),
  });

  return (
    <>
      <EmptyCell />

      <HeaderRow ref={headerRef} onScroll={onHeaderScroll}>
        <StyledHeadersWrapper>
          {dateRange.map((headerDate) => (
            <WeekHeader key={headerDate.toString()} date={headerDate} />
          ))}
        </StyledHeadersWrapper>
      </HeaderRow>

      <AxisColumn ref={axisRef} onScroll={onAxisScroll}>
        {timelineRangeLabels.map((hourLabel) => (
          <ScaleHourBlock key={hourLabel}>
            <Typography.Text>{hourLabel}</Typography.Text>
          </ScaleHourBlock>
        ))}
      </AxisColumn>

      <StyledTimetableContent
        className='global_slots'
        ref={contentRef}
        onScroll={onContentScroll}
      >
        <BookingTimetableContent
          fieldWithBookings={data}
          dates={dateRange}
          date={date}
          user={user}
          timelineHours={timelineHours}
          filteredSportId={filteredSportId}
        />
      </StyledTimetableContent>
    </>
  );
};
