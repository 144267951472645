import { apiClient, ApiClientConfig } from 'api';
import { CompanyType } from 'types';
import { AllBookingStatsResponse } from 'types/allbookingstats';
import { DayViewStatsResponse } from 'types/dayviewstats';

export type GetDayViewStatsParams = {
  date?: any;
  company_id?: any;
  facility_id?: any;
};

export type GetReportDTO = {
  data: {
    from_date: string;
    to_date: string;
  };
  params?: {
    company_type?: CompanyType;
    company_id?: string;
  };
};

export const ReportApiService = () => {
  const getConsolidatedReport = async (payload: GetReportDTO) => {
    const { data, params } = payload;
    const response = await apiClient({
      endpoint: '/admin/reports/consolidated',
      method: 'POST',
      data,
      params,
      config: {
        responseType: 'blob',
      },
    });

    return response;
  };

  const getAccountsReport = async (payload: GetReportDTO) => {
    const { data, params } = payload;

    const response = await apiClient({
      endpoint: '/admin/reports/accounts',
      method: 'POST',
      data,
      params,
      config: {
        responseType: 'blob',
      },
    });

    return response;
  };

  const getDayViewStats = async (
    params: GetDayViewStatsParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<DayViewStatsResponse>({
      ...callConfig,
      endpoint: `/admin/reports/dayviewstats`,
      params,
    });

    return response.data;
  };
  const getAllBookingStats = async (
    params: GetDayViewStatsParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<AllBookingStatsResponse>({
      ...callConfig,
      endpoint: `/admin/reports/bookingstats`,
      params,
    });

    return response.data;
  };
  // const getAllBookingStats = async () => {
  //   const response = await apiClient<AllBookingStatsResponse>({
  //     endpoint: `/admin/reports/bookingstats`,
  //     //params,
  //   });

  //   return response.data;
  // };

  const getSalesReport = async (payload: GetReportDTO) => {
    const { data, params } = payload;

    const response = await apiClient({
      endpoint: '/admin/reports/sales',
      method: 'POST',
      data,
      params,
      config: {
        responseType: 'blob',
      },
    });

    return response;
  };

  return {
    getConsolidatedReport,
    getAccountsReport,
    getSalesReport,
    getDayViewStats,
    getAllBookingStats,
  };
};
