import { DeleteOutlined, InboxOutlined } from '@ant-design/icons';
import { Checkbox, Col, Form, Grid, Image, Input, Row, Upload } from 'antd';
import { RcFile } from 'antd/lib/upload';
import ImgCrop from 'antd-img-crop';
import { Button } from 'components';
import { CompanySelect } from 'components/main-filters/company-select';
import { RouteLeavingGuard } from 'components/route-leaving-guard/route-leaving-guard';
import { AreaSelect } from 'features/areas/components/area-select/area-select';
import { useUploadProps } from 'hooks/use-upload-props';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Facility, PaymentMethod } from 'types';
import { paymentMethodTranslationsMap } from 'utils/transaction';
import { geolocationPattern } from 'utils/validations';

import { UpdateFacilityField, UpdateFacilityFormValues } from './types';

const { useBreakpoint } = Grid;
const { Dragger } = Upload;

type UpdateFacilityFormProps = {
  handleUpdate: (object: UpdateFacilityFormValues, FileList: RcFile[]) => void;
  handleDeleteImage: () => void;
  disabled: boolean;
  data: Facility;
};

export const UpdateFacilityForm = ({
  data,
  handleUpdate,
  handleDeleteImage,
  disabled,
}: UpdateFacilityFormProps) => {
  const formName = 'update-facility-form';
  const screens = useBreakpoint();
  const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
  const [form] = Form.useForm<UpdateFacilityFormValues>();
  const { t } = useTranslation();

  const { fileList, uploadProps } = useUploadProps();

  const [isFormDirty, setIsFormDirty] = useState(false);

  useEffect(() => {
    if (fileList.length) setIsFormDirty(true);
  }, [fileList]);

  const formItemLayout = screens.lg
    ? { labelCol: { span: 7 }, wrapperCol: { span: 10 } }
    : null;

  const companyGeolocation = data.geolocation
    ? `${data.geolocation.lat}, ${data.geolocation.lon}`
    : '';

  const getInitialValues = {
    [UpdateFacilityField.Company]: data.company_id,
    [UpdateFacilityField.CompanyName]: data.company_name,
    [UpdateFacilityField.Area]: data.area.id,
    [UpdateFacilityField.FacilityName]: data.name,
    [UpdateFacilityField.FacilityNameInArabic]: data.name_arabic,
    [UpdateFacilityField.Rank]: data.rank,
    [UpdateFacilityField.Geolocation]: companyGeolocation,
    [UpdateFacilityField.Image]: undefined,
    [UpdateFacilityField.Payment]: data.payment_methods,
  };

  const paymentOptions = [
    {
      value: PaymentMethod.Cash,
      label: paymentMethodTranslationsMap[PaymentMethod.Cash],
    },
    {
      value: PaymentMethod.Knet,
      label: paymentMethodTranslationsMap[PaymentMethod.Knet],
    },
    {
      value: PaymentMethod.Wallet,
      label: paymentMethodTranslationsMap[PaymentMethod.Wallet],
    },
    {
      value: PaymentMethod.Credit,
      label: paymentMethodTranslationsMap[PaymentMethod.Credit],
    },
    {
      value: PaymentMethod.Apple,
      label: paymentMethodTranslationsMap[PaymentMethod.Apple],
    },
  ];

  const onFinish = (formValues: UpdateFacilityFormValues) => {
    setIsFormDirty(false);
    handleUpdate(formValues, fileList as RcFile[]);
  };

  return (
    <>
      <Form<UpdateFacilityFormValues>
        {...formItemLayout}
        id={formName}
        name={formName}
        form={form}
        layout={screens.lg ? 'horizontal' : 'vertical'}
        initialValues={getInitialValues}
        onFinish={onFinish}
        onFinishFailed={() => {
          setValidateTrigger(['onChange']);
        }}
        onFieldsChange={() => setIsFormDirty(true)}
        validateTrigger={validateTrigger}
        disabled={disabled}
      >
        <Form.Item<UpdateFacilityField>
          label={t('facilities.formFields.company.label')}
          // name={UpdateFacilityField.Company}
          name={UpdateFacilityField.CompanyName}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <CompanySelect
            disabled={true}
            theme={'standard'}
            companyType={undefined}
            bordered={true}
            style={{ maxWidth: '100%' }}
            placeholder={t('facilities.formFields.company.placeholder')}
          />
        </Form.Item>
        <Form.Item<UpdateFacilityField>
          label={t('facilities.formFields.area.label')}
          name={UpdateFacilityField.Area}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <AreaSelect
            placeholder={t('facilities.formFields.area.placeholder')}
          />
        </Form.Item>
        <Form.Item<UpdateFacilityField>
          label={t('facilities.formFields.facilityName.label')}
          name={UpdateFacilityField.FacilityName}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input
            placeholder={t('facilities.formFields.facilityName.placeholder')}
          />
        </Form.Item>
        <Form.Item<UpdateFacilityField>
          label={t('facilities.formFields.facilityArabic.label')}
          name={UpdateFacilityField.FacilityNameInArabic}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input
            placeholder={t('facilities.formFields.facilityArabic.placeholder')}
            dir='rtl'
          />
        </Form.Item>
        <Form.Item<UpdateFacilityField>
          label={t('facilities.formFields.rank.label')}
          name={UpdateFacilityField.Rank}
          rules={[
            {
              required: true,
            },
            () => ({
              validator(_, value) {
                if (value <= 0) {
                  return Promise.reject(
                    t('facilities.formFields.rank.errorLessThanOne'),
                  );
                } else return Promise.resolve();
              },
            }),
          ]}
        >
          <Input placeholder={t('facilities.formFields.rank.placeholder')} />
        </Form.Item>
        <Form.Item<UpdateFacilityField>
          label={t('facilities.formFields.geolocation.label')}
          name={UpdateFacilityField.Geolocation}
          rules={[
            {
              required: true,
            },
            {
              pattern: geolocationPattern,
              message: t('validateMessages.custom.geolocation'),
            },
          ]}
        >
          <Input
            placeholder={t('facilities.formFields.geolocation.placeholder')}
          />
        </Form.Item>
        <Form.Item
          label={t('facilities.formFields.image.label')}
          getValueFromEvent={(event) => event?.fileList}
        >
          <ImgCrop rotationSlider aspect={2}>
            {data.image_url ? (
              <div style={{ display: 'flex' }}>
                <Image
                  width={200}
                  style={{ borderRadius: '6px' }}
                  src={data.image_url}
                  placeholder={true}
                  preview={false}
                />
                <Button
                  danger
                  onClick={handleDeleteImage}
                  style={{ borderColor: 'red', padding: '4px 10px' }}
                >
                  <DeleteOutlined />
                </Button>
              </div>
            ) : (
              <>
                {fileList.length ? (
                  <Upload
                    {...uploadProps}
                    name='file'
                    accept='.png, .jpg, .jpeg'
                    maxCount={1}
                    listType='picture-card'
                  />
                ) : (
                  <ImgCrop rotationSlider>
                    <Dragger {...uploadProps} multiple={false}>
                      <p className='ant-upload-drag-icon'>
                        <InboxOutlined />
                      </p>
                      <p className='ant-upload-text'>
                        {t('upload.dragOrClick')}
                      </p>
                      <p className='ant-upload-hint'>
                        <Trans
                          i18nKey='upload.hint'
                          components={{ br: <br /> }}
                        />
                      </p>
                    </Dragger>
                  </ImgCrop>
                )}
              </>
            )}
          </ImgCrop>
        </Form.Item>
        <Form.Item<UpdateFacilityField>
          label={t('facilities.formFields.payment.label')}
          name={UpdateFacilityField.Payment}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Checkbox.Group options={paymentOptions} />
        </Form.Item>

        <Col span={screens.lg ? 10 : 'auto'} offset={screens.lg ? 7 : 0}>
          <Row justify='end'>
            <Button disabled={disabled} type='primary' htmlType='submit'>
              {t('facilities.buttons.update')}
            </Button>
          </Row>
        </Col>
      </Form>
      <RouteLeavingGuard blocked={isFormDirty} />
    </>
  );
};
