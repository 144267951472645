import { App, Grid, Input, Switch, TablePaginationConfig } from 'antd';
import { FilterValue, SorterResult } from 'antd/es/table/interface';
import { Column, RecordCountBadge, Table } from 'components';
import {
  StyledTableHeader,
  StyledTableWrapper,
} from 'components/table/table.styled';
import { FacilitiesResponse } from 'features/facilities/api/facilities-api.service';
import { FacilitiesFilterFormPaginated } from 'features/facilities/components/facilities-filter-form/types';
import { useUpdateFacility } from 'features/facilities/use-cases/update-facility';
import { usePermissions } from 'features/users';
import { flatten, uniq } from 'lodash';
import { Dispatch, KeyboardEvent, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'routes/constants';
import { getRoute } from 'routes/utils';
import { Facility } from 'types/facility';

const { useBreakpoint } = Grid;

type FacilitiesTableProps = {
  isLoading: boolean;
  data?: FacilitiesResponse;
  tableParams: FacilitiesFilterFormPaginated;
  updateParams: Dispatch<SetStateAction<FacilitiesFilterFormPaginated>>;
};

export const FacilitiesTable = ({
  data,
  isLoading,
  tableParams,
  updateParams,
}: FacilitiesTableProps) => {
  const { t } = useTranslation();
  const screens = useBreakpoint();
  const { message } = App.useApp();
  const permissions = usePermissions();
  const navigate = useNavigate();
  const [editingId, setEditingId] = useState('');

  const { mutate: mutateUpdateFacility, isLoading: isLoadingUpdateFacility } =
    useUpdateFacility();

  if (!permissions) return null;

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<Facility> | SorterResult<Facility>[],
  ) => {
    let orderBy_ = 'name';
    if (!Array.isArray(sorter)) {
      if (sorter.order === 'ascend' && sorter.field === 'rank') {
        orderBy_ = 'rank';
      } else if (sorter.order === 'descend' && sorter.field === 'rank') {
        orderBy_ = '-rank';
      } else if (sorter.order === 'ascend' && sorter.field === 'name') {
        orderBy_ = 'name';
      } else if (sorter.order === 'descend' && sorter.field === 'name') {
        orderBy_ = '-name';
      }
    }

    // sorter.order === 'ascend'
    // ? 'name'
    // : '-name',
    updateParams({
      ...tableParams,
      page: pagination.current,
      size: pagination.pageSize,
      order_by: Array.isArray(sorter) ? undefined : orderBy_,
    });
  };

  const tableWidth = screens.xxl ? { x: 1440 } : { x: 1200 };

  const isEditing = (id: string) => {
    return editingId === id;
  };

  const handleRankChange = (
    id: string,
    event: KeyboardEvent<HTMLInputElement>,
  ) => {
    const newValue = +(event.target as HTMLInputElement).value;
    if (newValue <= 0) {
      message.error(t('facilities.formFields.rank.errorLessThanOne'));
    } else {
      mutateUpdateFacility({
        id,
        rank: +(event.target as HTMLInputElement).value,
      });
      setEditingId('');
    }
  };

  return (
    <StyledTableWrapper>
      <StyledTableHeader>
        <RecordCountBadge
          text={t('facilities.foundFacilities')}
          count={data?.total || 0}
        />
      </StyledTableHeader>
      <Table<Facility>
        loading={isLoading}
        dataSource={data?.items}
        rowKey='id'
        onChange={handleTableChange}
        pagination={{
          current: tableParams.page,
          defaultPageSize: tableParams.size,
          total: data?.total,
        }}
        scroll={tableWidth}
        onRow={(record) => {
          return {
            onClick: () => {
              const selection = window?.getSelection()?.toString();

              if (!selection?.length) {
                navigate(getRoute(ROUTES.FACILITIES_EDIT, record.id));
              }
            },
          };
        }}
      >
        <Column<Facility>
          title={t('facilities.table.rank')}
          dataIndex={'rank'}
          sorter={true}
          sortDirections={['ascend', 'descend', 'ascend']}
          defaultSortOrder={'descend'}
          width={screens.xxl ? 'auto' : 360}
          onCell={(record) => {
            return {
              onClick: (event) => {
                event.stopPropagation();
                setEditingId(record?.id);
              },
            };
          }}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          render={(value: any, record: any) => {
            const editable = isEditing(record?.id);

            return editable ? (
              <Input
                defaultValue={value}
                onPressEnter={(e) => handleRankChange(record?.id, e)}
              />
            ) : (
              <div>{record?.rank}</div>
            );
          }}
        />
        <Column<Facility>
          title={t('facilities.table.facility')}
          dataIndex={'name'}
          sorter={true}
          sortDirections={['ascend', 'descend', 'ascend']} // workaround to remove null as an option
          defaultSortOrder={'ascend'}
          width={screens.xxl ? 'auto' : 360}
        />
        <Column<Facility>
          title={t('facilities.table.company')}
          dataIndex={'company_name'}
          width={screens.xxl ? 'auto' : 300}
          render={(_, record) => record.company.name}
        />
        <Column<Facility>
          title={t('facilities.table.area')}
          dataIndex={'area.name'}
          width={screens.xxl ? 'auto' : 270}
          render={(_, record) => record.area.name}
        />
        <Column<Facility>
          title={t('facilities.table.sport')}
          dataIndex={'sport'}
          width={screens.xxl ? 'auto' : 220}
          render={(_, record) =>
            uniq(
              flatten(
                record.fields?.map((field) =>
                  field.sports.map((sport) => sport.name),
                ),
              ),
            ).join(', ')
          }
        />
        <Column<Facility>
          title={t('facilities.table.enableDisable')}
          width={screens.xxl ? 'auto' : 162}
          dataIndex={'is_active'}
          render={(_, record) => (
            <Switch
              disabled={!permissions?.facilities.edit}
              defaultChecked={record.is_active}
              checked={record.is_active}
              loading={isLoadingUpdateFacility}
              onChange={(isChecked) =>
                mutateUpdateFacility({
                  id: record.id,
                  is_active: isChecked,
                })
              }
              onClick={(checked, event) => {
                event.stopPropagation();
                event.preventDefault();
              }}
            />
          )}
        />
      </Table>
    </StyledTableWrapper>
  );
};
