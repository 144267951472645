import {
  DownOutlined,
  MailOutlined,
  PhoneOutlined,
  SmileOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Form, Input, Spin } from 'antd';
import { Loader, Modal, Result404, Select } from 'components';
import { UpdateUserDTO } from 'features/users/api/user-api.service';
import { StyledUserModalWrapper } from 'features/users/components/modals/user-modal.styles';
import {
  EndUserFormField,
  EndUserFormValues,
} from 'features/users/components/users-filter-form';
import { useGenderOptions } from 'features/users/hooks/use-gender-options';
import { useGetUser, useUpdateUser } from 'features/users/use-cases';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Gender } from 'types';
import { phoneNumberPattern } from 'utils/validations';

export const EditEndUserModal = ({
  onClose,
  onSuccess,
  id,
}: {
  onClose: () => void;
  onSuccess: () => void;
  id: string;
}) => {
  const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
  const formName = 'edit-end-user-form';
  const [form] = Form.useForm<EndUserFormValues>();
  const { t } = useTranslation();

  const userQueryResult = useGetUser(id, {
    cacheTime: 0, //workaround to invalidate initialValues of client data form
  });

  const {
    isLoading: isLoadingGetUser,
    isFetching: isFetchingGetUser,
    data: userData,
    isError: isErrorUser,
  } = userQueryResult;

  const genderOptions = useGenderOptions();

  const { mutate: mutateUpdateUser, isLoading: isLoadingUpdateUser } =
    useUpdateUser();

  const isLoading = {
    loading: isLoadingGetUser || isFetchingGetUser || isLoadingUpdateUser,
    isLoadingGetUser,
    isLoadingUpdateUser,
  };

  const handleUpdate = (formData: EndUserFormValues) => {
    const payload: UpdateUserDTO = {
      id: id,
      first_name: formData.first_name,
      last_name: formData.last_name,
      phone_number: formData.phone_number,
      email: formData.email.length ? formData.email : null,
      role: 'User',
      gender: formData.gender,
    };
    mutateUpdateUser(payload, { onSuccess });
  };

  const getInitialValues: EndUserFormValues = {
    [EndUserFormField.FirstName]: userData?.first_name || '',
    [EndUserFormField.LastName]: userData?.last_name || '',
    [EndUserFormField.PhoneNumber]: userData?.phone_number || '',
    [EndUserFormField.Email]: userData?.email || '',
    [EndUserFormField.Gender]: userData?.gender || Gender.NotSpecified,
  };

  return (
    <StyledUserModalWrapper>
      <Modal
        open
        centered
        title={t('adminUsers.userInfo')}
        onCancel={onClose}
        getContainer={false}
        onOk={form.submit}
        okText={t('adminUsers.updateUser')}
        cancelButtonProps={{ style: { display: 'none' } }}
        okButtonProps={{
          form: formName,
        }}
      >
        {isLoadingGetUser ? (
          <Loader />
        ) : isErrorUser ? (
          <Result404 />
        ) : (
          <Spin spinning={isLoading.loading}>
            <Form<EndUserFormValues>
              id={formName}
              name={formName}
              form={form}
              layout='vertical'
              initialValues={getInitialValues}
              onFinish={handleUpdate}
              requiredMark={'optional'}
              onFinishFailed={() => {
                setValidateTrigger(['onChange']);
              }}
              validateTrigger={validateTrigger}
              disabled={isLoading.loading}
            >
              <Form.Item<EndUserFormField>
                label={t('adminUsers.formFields.firstName.label')}
                name={EndUserFormField.FirstName}
                rules={[
                  {
                    required: true,
                    whitespace: true,
                  },
                ]}
              >
                <Input
                  placeholder={t('adminUsers.formFields.firstName.placeholder')}
                  prefix={<UserOutlined className='input-prefix-icon' />}
                />
              </Form.Item>
              <Form.Item<EndUserFormField>
                label={t('adminUsers.formFields.lastName.label')}
                name={EndUserFormField.LastName}
                rules={[
                  {
                    required: true,
                    whitespace: true,
                  },
                ]}
              >
                <Input
                  placeholder={t('adminUsers.formFields.lastName.placeholder')}
                  prefix={<UserOutlined className='input-prefix-icon' />}
                />
              </Form.Item>

              <Form.Item<EndUserFormField>
                label={t('adminUsers.formFields.gender.label')}
                name={EndUserFormField.Gender}
                rules={[
                  {
                    required: false,
                    whitespace: true,
                  },
                ]}
              >
                <Select
                  options={genderOptions}
                  placeholder={t('adminUsers.formFields.gender.placeholder')}
                  className='has-prefix-icon'
                  suffixIcon={
                    <>
                      <SmileOutlined className='prefix-icon' />
                      <DownOutlined className='suffix-icon' />
                    </>
                  }
                />
              </Form.Item>

              <Form.Item<EndUserFormField>
                label={t('adminUsers.formFields.email.label')}
                name={EndUserFormField.Email}
                rules={[
                  {
                    required: false,
                    whitespace: true,
                  },
                  {
                    type: 'email',
                    message: t('validateMessages.custom.email'),
                  },
                ]}
              >
                <Input
                  placeholder={t('adminUsers.formFields.email.placeholder')}
                  prefix={<MailOutlined className='input-prefix-icon' />}
                />
              </Form.Item>

              <Form.Item<EndUserFormField>
                label={t('adminUsers.formFields.phoneNumber.label')}
                name={EndUserFormField.PhoneNumber}
                rules={[
                  {
                    required: true,
                  },
                  {
                    pattern: phoneNumberPattern,
                    message: t('validateMessages.custom.phoneNumber'),
                  },
                ]}
              >
                <Input
                  type='tel'
                  placeholder={t(
                    'adminUsers.formFields.phoneNumber.placeholder',
                  )}
                  prefix={<PhoneOutlined className='input-prefix-icon' />}
                />
              </Form.Item>
            </Form>
          </Spin>
        )}
      </Modal>
    </StyledUserModalWrapper>
  );
};
