import {
  CalendarOutlined,
  ClockCircleOutlined,
  DollarCircleOutlined,
  EnvironmentOutlined,
} from '@ant-design/icons';
import { Badge, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { colors } from 'styles';
import { Booking, PaymentMethod } from 'types';
import { formatPrice } from 'utils/currency';
import {
  DEFAULT_TIMEZONE,
  formatDate,
  formatDateRange,
  getDuration,
} from 'utils/date';
import { paymentMethodTranslationsMap } from 'utils/transaction';

import {
  StyledAdditionalItemText,
  StyledDetailsItem,
  StyledItemText,
  StyledTruncationWrapper,
  StyledWrapper,
} from './booking-details-info.styles';

type BookingDetailsInfoProps = {
  startDate: string;
  endDate: string;
  fieldName: string;
  companyName: string;
  facilityName: string;
  initialPrice: number;
  finalPrice: number;
  discount: number;
  paymentMethod: PaymentMethod;
};

export const getBookingDetailsInfoProps = (booking: Booking) => {
  return {
    startDate: booking.start_date,
    endDate: booking.end_date,
    fieldName: booking.field.name,
    facilityName: booking.field.facility.name,
    companyName: booking.field.facility.company.name,
    initialPrice: booking?.transaction?.total_cost || 0,
    finalPrice: booking?.transaction?.total_cost_with_discount || 0,
    discount: booking?.transaction?.discount || 0,
    paymentMethod: booking?.transaction?.payment_method || '',
  };
};

export const BookingDetailsInfo = ({
  startDate,
  endDate,
  fieldName,
  companyName,
  facilityName,
  initialPrice,
  finalPrice,
  discount,
  paymentMethod,
}: BookingDetailsInfoProps) => {
  const { t } = useTranslation();

  const duration = getDuration(startDate, endDate);

  return (
    <StyledWrapper>
      <StyledDetailsItem>
        <CalendarOutlined className='booking-details-icon' />
        <div>
          <StyledItemText>
            {formatDate({
              date: startDate,
              format: 'dateWithDayName',
              timeZone: DEFAULT_TIMEZONE,
            })}
          </StyledItemText>
        </div>
      </StyledDetailsItem>

      <StyledDetailsItem>
        <ClockCircleOutlined />
        <div>
          <StyledItemText>
            {formatDateRange({
              startDate: startDate,
              endDate: endDate,
            })}
          </StyledItemText>
          <StyledAdditionalItemText type='secondary'>
            {t('common.minutes.short', { value: duration })}
          </StyledAdditionalItemText>
        </div>
      </StyledDetailsItem>

      <StyledDetailsItem>
        <EnvironmentOutlined className='booking-details-icon' />
        <StyledTruncationWrapper>
          <StyledItemText ellipsis={{ tooltip: true }}>
            {fieldName}
          </StyledItemText>
          <StyledAdditionalItemText
            type='secondary'
            ellipsis={{ tooltip: true }}
          >
            {companyName} - {facilityName}
          </StyledAdditionalItemText>
        </StyledTruncationWrapper>
      </StyledDetailsItem>

      <StyledDetailsItem>
        <DollarCircleOutlined className='booking-details-icon' />
        <StyledTruncationWrapper>
          <Space size='small'>
            <Badge
              count={formatPrice({
                value: finalPrice,
                currency: 'KWD',
              })}
              showZero
              color={colors.gray3}
              className='booking-details-price-badge'
            />
            <StyledItemText>
              {paymentMethodTranslationsMap[paymentMethod]}
            </StyledItemText>
          </Space>

          {discount > 0 ? (
            <StyledAdditionalItemText
              type='secondary'
              ellipsis={{ tooltip: true }}
            >
              {t('bookings.details.discountedFromTo', {
                from: formatPrice({
                  value: initialPrice,
                  currency: 'KWD',
                }),
                to: formatPrice({
                  value: finalPrice,
                  currency: 'KWD',
                }),
              })}
            </StyledAdditionalItemText>
          ) : null}
        </StyledTruncationWrapper>
      </StyledDetailsItem>
    </StyledWrapper>
  );
};
