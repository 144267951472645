import {
  apiClient,
  ApiClientConfig,
  PaginatedRequestParams,
  PaginatedResponse,
} from 'api';
import { axiosInstance } from 'api/axios-instance';
import { CONFIG } from 'config';
import { Company, CompanyType } from 'types';

export type CompaniesResponse = PaginatedResponse<Company>;

export type CompaniesParams = {
  is_active?: boolean;
  company_type?: CompanyType;
  phone_number?: string;
  company_name?: string;
} & PaginatedRequestParams;

export type CreateCompanyDTO = {
  is_active?: boolean;
  email?: string;
  phone_number?: string;
  name?: string;
  name_arabic?: string;
  address?: string;
  country?: string;
  lat?: string;
  lon?: string;
  type?: CompanyType;
};

export type UpdateCompanyDTO = {
  id: string;
} & CreateCompanyDTO;

export const CompaniesApiService = () => {
  const getCompanies = async (
    params: CompaniesParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<CompaniesResponse>({
      ...callConfig,
      endpoint: `/admin/companies`,
      params,
    });

    return response.data;
  };

  const createCompany = async (payload: CreateCompanyDTO) => {
    const response = await apiClient({
      endpoint: `/admin/companies`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };

  const updateCompany = async (
    payload: UpdateCompanyDTO,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const { id, ...data } = payload;

    const response = await apiClient<Company>({
      ...callConfig,
      endpoint: `/admin/companies/${id}`,
      method: 'PATCH',
      data,
    });

    return response.data;
  };

  const getCompany = async (
    id: string,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<Company>({
      ...callConfig,
      endpoint: `/admin/companies/${id}`,
    });

    return response.data;
  };

  const uploadCompanyImage = async (id: string, formData: FormData) => {

    // Replace baseUrl for Sports API if the request is for Sports APIs.. 
  const sportsService = replaceSubstring(CONFIG.LI3IB_API_BASE_URL, '/identity/', '/sports/');  


    const response = await axiosInstance.post(
      `${sportsService}/v1/admin/companies/${id}/image`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );

    return response.data;
  };

  const deleteCompanyImage = async (
    id: string,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient({
      ...callConfig,
      endpoint: `/admin/companies/${id}/image`,
      method: 'DELETE',
    });

    return response.data;
  };

  const replaceSubstring = (
    input: string,
    target: string,
    replacement: string,
  ): string => {
    // alert(input);
    // alert(target);
    // alert(replacement);
    const startIndex = input.indexOf(target);
    // alert(startIndex);
    const endIndex = startIndex + target.length;
    // alert(endIndex);
    const replacedStr =
      input.slice(0, startIndex) + replacement + input.slice(endIndex);
    // alert(replacedStr); // "Hello, John!"
  
    return replacedStr;
    // return input.replace(new RegExp(target, 'g'), replacement);
  };
  
  return {
    createCompany,
    getCompanies,
    getCompany,
    updateCompany,
    uploadCompanyImage,
    deleteCompanyImage,
  };
};
