type RoutesType = typeof ROUTES;
type RouteKeys = keyof RoutesType;
export type RoutePaths = RoutesType[RouteKeys];

export const ROUTES = {
  HOME: '/',
  BOOKINGS: '/bookings',
  BOOKINGS_CREATE: `/bookings/create`,
  BOOKINGS_EDIT: `/bookings/:id/edit`,
  FIXED_BOOKINGS: '/fixed-bookings',
  FIXED_BOOKING_DETAILS: '/fixed-bookings/:id/bookings',
  FIXED_BOOKING_DETAILS_COMMENTS: '/fixed-bookings/:id/comments',
  FIXED_BOOKING_DETAILS_INVOICE: '/fixed-bookings/:id/invoice',
  FIXED_BOOKINGS_CREATE: '/fixed-bookings/create',
  FIXED_BOOKINGS_CREATE_INVOICE: '/fixed-bookings/create-invoice',
  FIXED_BOOKINGS_EDIT: '/fixed-bookings/:id/bookings/:booking_id/edit',
  DAY_VIEW: '/day-view',
  WEEK_VIEW: '/week-view',
  USERS: '/users',
  USERS_APP: '/users/app',
  USERS_APP_EDIT: `/users/app/:id/edit`,
  USERS_ADMIN: '/users/admin',
  USERS_ADMIN_EDIT: `/users/admin/:id/edit`,
  COMPANIES_ALL: '/companies-all', // for unique menu key purposes - not a real route
  COMPANIES: '/companies',
  COMPANIES_CREATE: '/companies/create',
  COMPANIES_EDIT: '/companies/:id/edit',
  FACILITIES: '/facilities',
  FACILITIES_CREATE: '/facilities/create',
  FACILITIES_EDIT: '/facilities/:id/edit',
  FIELDS: '/fields',
  FIELDS_CREATE: '/fields/create',
  FIELDS_EDIT: '/fields/:id/edit',
  SPORTS: '/sports',
  AMENITIES: '/amenities',
  WALLET: '/wallet',
  WALLET_TRANSACTIONS: '/wallet/:id/transactions',
  WALLET_TRANSACTION_DETAILS: '/wallet/:id/transactions/:transaction_id',
  LOGIN: '/login',
  ACCOUNT_SETTINGS: '/account-settings',
  COMPANY_INFO: '/company-info',
  CONTACT: '/company-info/contact', // for unique menu key purposes - not a real route
  CONTACT_US: '/company-info/contact-us',
  PRIVACY_POLICY: '/company-info/privacy-policy',
  REPORTS: '/reports',
  NOT_FOUND: '*',
} as const;
